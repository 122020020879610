import React, { Suspense } from "react"
import { graphql } from "gatsby"

import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useI18next } from 'gatsby-plugin-react-i18next'

import SmoothScroll from "../../components/layout/smoothScroll";

import Layout from '../../components/layout/layout'
import MenuGlobal from '../../components/menu'

import '../../sass/app.scss';
import '../../sass/homepage.scss';

const Homepage = ({ location, data }) => {

  const breakpoints = useBreakpoint();
  const publicsUrl = data.allFile

  const { language } = useI18next();

  const { t } = useI18next()
  const dataGlobal = t("global", { returnObjects: true })
  const dataIndex = t("homepage", { returnObjects: true })


  const ProjetosDestaques = React.lazy(() => import("../../components/homepage/projetosDestaque"));
  const Slogan = React.lazy(() => import("../../components/slogan3"));
  const Branding = React.lazy(() => import("../../components/homepage/branding"));
  const BrandingMobile = React.lazy(() => import("../../components/homepage/brandingMobile"));
  const Team = React.lazy(() => import("../../components/homepage/team"));
  const ClientsLogos = React.lazy(() => import("../../components/homepage/clientsLogos"));

  return (
    <Layout home mobile={useBreakpoint().mobile} post={null} data={dataGlobal} page={dataIndex.page} seo={dataIndex.seo} location={location} language={language}>

      {breakpoints.mobile ?
        <>
          <MenuGlobal video src="/assets/videos/Homepage-mobile.mp4"
            global={dataGlobal} homepage mobile location={location} />
          <SmoothScroll>

            <Suspense >
              <Slogan data={dataIndex.firstsloganmobile} index={0} mobile />
            </Suspense>

            <Suspense >
              <ProjetosDestaques data={dataIndex.destaques?.first} index={1} publicsUrl={publicsUrl.edges} mobile />
            </Suspense>

            <div className="container-bg-over-video bg-black">

              <Suspense >
                <ProjetosDestaques data={dataIndex.destaques?.second} index={2} mobile />
              </Suspense>

              <Suspense >
                <BrandingMobile data={dataIndex.branding} />
              </Suspense>

              <Suspense >
                <ProjetosDestaques data={dataIndex.destaques?.third} index={3} mobile />
              </Suspense>

              <div className="container-margin-small-height"></div>

              <Suspense >
                <ProjetosDestaques data={dataIndex.destaques?.fourth} index={4} mobile />
              </Suspense>

              <Suspense >
                <Team data={dataIndex.team} publicsUrl={publicsUrl.edges} mobile />
              </Suspense>

              <Suspense >
                <Slogan data={dataIndex.secondslogan} index={1} mobile />
              </Suspense>

              <Suspense >
                <ClientsLogos data={dataIndex.team} publicsUrl={publicsUrl.edges} mobile />
              </Suspense>


              <div className="container-scroll-heigth" />
            </div>
          </SmoothScroll>
        </>
        :
        <>
          <MenuGlobal video src="/assets/videos/Homepage-Desktop.mp4"
            global={dataGlobal} homepage location={location} teste />
          <SmoothScroll>

            <Suspense >
              <Slogan data={dataIndex.firstslogan} index={0} />
            </Suspense>

            <Suspense >
              <ProjetosDestaques data={dataIndex.destaques?.first} index={1} publicsUrl={publicsUrl.edges} />
            </Suspense>

            <div className="container-bg-over-video bg-black">

              <Suspense >
                <ProjetosDestaques data={dataIndex.destaques?.second} index={2} />
              </Suspense>

              <Suspense >
                <Branding data={dataIndex.branding} />
              </Suspense>

              <Suspense >
                <ProjetosDestaques data={dataIndex.destaques?.third} index={3} />
              </Suspense>

              <div className="container-margin-small-height"></div>
              <Suspense >
                <ProjetosDestaques data={dataIndex.destaques?.fourth} index={4} />
              </Suspense>

              <div className="container-margin-small-height"></div>
              <Suspense >
                <Team data={dataIndex.team} publicsUrl={publicsUrl.edges} />
              </Suspense>

              <Suspense >
                <Slogan data={dataIndex.secondslogan} index={1} />
              </Suspense>

              <Suspense >
                <ClientsLogos data={dataIndex.team} publicsUrl={publicsUrl.edges} />
              </Suspense>



              <div className="container-scroll-heigth" />
            </div>

          </SmoothScroll>
        </>
      }

    </Layout >

  )
}

export default Homepage


export const pageQuery = graphql`
          query($language: String!) {
            locales: allLocale(filter: {ns: {in: ["global", "homepage"]}, language: {eq: $language}}) {
            edges {
            node {
            ns
            data
          language
          }
        }
    }
          allFile {
            edges {
            node {
            relativePath
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
          `