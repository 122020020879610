import enTranslations from '../../locales/en/url.json';
import ptTranslations from '../../locales/pt/url.json';

export const getTranslatedPath = (path, language, dataAllPages) => {

    // Filtra as rotas que correspondem à URL atual e ao idioma
    const matchingPath = dataAllPages.allSitePage.edges.find(({ node }) => {
        const segments = node.path.split('/');
        return segments[1] === language;
    });

    return matchingPath ? matchingPath.node.path : path;
};

export const findPagePath = ({ language }) => {
    const translations = {
        en: enTranslations,
        pt: ptTranslations
    };
    return translations[language]?.urls || null;
};