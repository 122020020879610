import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';

import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import Layout from '../components/layout/layout'
import MenuGlobal from '../components/menu'
import NotFound from '../components/notFound'

import enTranslations from '../../locales/en/url.json';
import ptTranslations from '../../locales/pt/url.json';

import '../sass/app.scss';

const NotFoundPage = ({ location }) => {

  const { t } = useTranslation()
  const data = t("global", { returnObjects: true })
  const breakpoints = useBreakpoint();

  const pathname = location.pathname;
  const { language, changeLanguage } = useI18next();

  useEffect(() => {
    const findMatchingPath = (lng) => {
      const translations = lng === 'en' ? enTranslations : ptTranslations; // ... e assim por diante para outras linguagens
      var basePath = pathname.replace(["/en/"], ''); // Remove o prefixo da linguagem

      if (basePath[0] == '/') basePath = basePath.slice(1)

      return [
        lng,
        Object.values(translations).some(key => basePath.includes(key))
      ];
    };

    var [lng, urlExistsInLng] = findMatchingPath(language)

    // // Verifica se o path atual existe na linguagem atual
    if (urlExistsInLng) {
      if (pathname.includes('/en/')) {
        navigate(lng === 'pt' ? `/${pathname.replace(["/en/"], '')}` : `/${lng}${pathname.replace(["/en/"], '')}`);
      }
      else {
        if (language !== 'pt') {
          changeLanguage(lng, `/${lng}${pathname}`)
          return;
        } else {
          changeLanguage(lng, `/${pathname}`)
          return;
        }
      }
    } else {
      // Verifica em outras linguagens
      const otherLanguages = ['en', 'pt']; // Substitua por um array com todas as linguagens
      for (const lang of otherLanguages) {
        if (lang !== language && findMatchingPath(lang)[1]) {
          changeLanguage(lang, `${pathname}`);
          return;
        }
      }
    }

    // Se não encontrar em nenhuma linguagem, redireciona para 404
  }, []);

  return (
    <Layout mobile={useBreakpoint().mobile} post={null} data={data} noFooter={true} page={data.notfound.page} seo={data.notfound.seo} noindex location={location}>

      {breakpoints.mobile ? <MenuGlobal video src="/assets/videos/404_versao_mobile_ja9yms-1.mp4"
        global={data} mobile noFilter location={location} noScroll />
        : <MenuGlobal video src="/assets/videos/404_versao_desktop_soy4de-1.mp4"
          global={data} noFilter location={location} noScroll />
      }
      <NotFound data={data.notfound} />
    </Layout>
  )
}

export default NotFoundPage


export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["global", "homepage"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`