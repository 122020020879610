import * as React from "react"
import { graphql } from "gatsby"

import { useI18next } from 'gatsby-plugin-react-i18next'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Homepage from "../components/homepage/page"
import AboutPage from "./about"
import ContactsPage from "./contacts"
import WorkPage from "./work"
import NotFoundPage from "../components/notFound";

import { findPagePath } from '../utils/getTranslatedPath';

import '../sass/app.scss';
import '../sass/homepage.scss';

const IndexPage = ({ location,data }) => {

  const { language } = useI18next();

  if (typeof document !== 'undefined')
    return (
      <BrowserRouter>
        <Routes>
          {['/', '/en'].map(path => (
            <Route key={path} path={path} element={<Homepage data={data} location={location} />} />
          ))}
          
          <Route path={`/${language}${findPagePath(language)?.about}`} element={<AboutPage />} />
          <Route path={`/${language}${findPagePath(language)?.contacts}`} element={<ContactsPage />} />
          <Route path={`/${language}${findPagePath(language)?.work}`} element={<WorkPage />} />

          <Route component={<NotFoundPage />} />
        </Routes>
      </BrowserRouter >
    );
}

export default IndexPage


export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["global", "homepage"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
    allFile {
      edges {
        node {
          relativePath
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`